import React, { useState } from 'react'
import jablko from "../Images/jablko.svg"
import rastlinka from "../Images/rastlinka.svg"
import hviezdicka from "../Images/hviezdicka.svg"
import natalia from "../Photos/foto Natalia.png"
import hviedzicka2 from "../Images/hviezdicka_2.svg"
import oblak from "../Images/oblak.svg"

function KtoSom({language}) {

    const [lang, setLang]= useState(false)
  
    const styles={}

    const text1 = "Volám sa Natália Polanská. S deťmi pracujem už 20 rokov. Študovala som v Londýne, kde som taktiež mala svoju day care. Po 15 rokoch strávených v Anglicku teraz moje skúsenosti posúvam ďalej. V Londýne som sa naučila pracovať s deťmi a vždy bolo pre mňa dôležité dopriať im radosť z učenia, prinášať nové vnemy, zážitky a meniť veci k lepšiemu. Deti neporovnávam a verím v každého individualitu.                                                            "
    const text2 = "My name is Natalia Polanská. I have been working with children for over 20 years. I studied in London, where I also had my day care. I lived in London for 15 years and now I am passing on my experience. In London, I learned to work with children and it was always important for me to give children the joy of learning, bring new sensations, experiences and change things for the better. I don't compare children and I believe in everyone's individuality."
    
  return (
    <div class='bg-[#fffaeb] h-screen w-screen pt-32' id="ktosom">
      <div id="ktoSomMid" class='absolute top-[1025px]'></div>

        <div class="flex-row pt-52 lg:pt-8 xl:pt-52 hidden s:block">
          <div class=" flex-row">
            <div class="flex items-center justify-center">

            </div> 
          </div>
        </div>

        <div class="flex z-20 flex-row 
                    w-full 
                    sm:w-full 
                    md:w-1800px md:gap-0 md:m-0 md:px-16 
                    lg:w-[1800px] lg:gap-0 lg:m-0 lg:px-24 
                    xl:w-[1800px] xl:gap-8 xl:m-6 xl:px-48
                    xxl:w-[1200px] xxl:gap-8 xxl:m-6 xxl:px-48  
                    2xl:w-[1800px] 2xl:gap-24 2xl:m-8 2xl:px-72">
            <div class="p-6 text-black">
              <h3 class="text-yellow-300 pb-12 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg" style={styles.headerText}>{language ? "ABOUT ME" : "KTO SOM"}</h3>
              <p id="divKtoSomText" class="font-[satoshi-medium] 2xl:text-2xl xxl:text-lg xl:text-xl lg:text-base md:text-md sm:text-sm text-[11px] leading-[0.75rem]" style={styles.text}>{language ? text2 : text1}</p>
            </div>

            <div class="p-6 lg:pt-0 xl:pt-6 flex-row relative text-black ">
                <img id="imgJablko" class="
                md:landscape:right-[80px] lg:landscape:right-[1080px] xl:landscape:right-[100px]
                md:landscape:top-[220px] lg:landscape:top-[400px] xl:landscape:top-[400px]
                md:landscape:w-[150px] lg:landscape:w-[150px] xl:landscape:w-[200px]                
                xxl:landscape:top-[280px] xxl:landscape:w-[150px]  xxl:landscape:right-[70px]
                2xl:landscape:top-[360px] 2xl:landscape:w-[200px]  2xl:landscape:right-[100px]
                top-[190px] right-[25px] w-[50px]
                s:top-[240px] s:right-[40px] s:w-[90px]
                sm:top-[360px] sm:right-[100px] sm:w-[50px]


                z-10  s:w-[90px] sm:w-[130px]
                 md:w-[150px] md:top-[180px] sm:landscape:right-[110px] lg:w-[170px] 2xl:w-[200px]
                   lg:top-[400px] sm:top-[360px] sm:right-[100px] relative " style={styles.jablko} src={jablko} alt="" />
                
                <img id="imgRastlinka" class="
                md:landscape:left-[180px] lg:landscape:left-[-860px] xl:landscape:left-[230px]
                md:landscape:bottom-[220px] lg:landscape:bottom-[-40px] xl:landscape:bottom-[150px]
                md:landscape:w-[150px] lg:landscape:w-[140px] xl:landscape:w-[170px]
                xxl:landscape:left-[210px] xxl:landscape:bottom-[150px] xxl:landscape:w-[170px]
                2xl:landscape:left-[230px] 2xl:landscape:bottom-[150px] 2xl:landscape:w-[170px]


                bottom-[-40px] landscape:bottom-[0px]  left-[45px] 
                landscape:left-[100px] w-[40px] 
                s:left-[100px] s:bottom-[70px]
                sm:bottom-[190px] sm:left-[0px]

                z-10 s:w-[90px] sm:w-[130px]
                 md:w-[150px] md:left-[180px] md:bottom-[220px] lg:w-[170px] lg:left-[230px] 
                 xl:left-[230px] xl:bottom-[150px] 2xl:w-[200px]   relative " style={styles.rastlinka} src={rastlinka} alt="" />
                
                <img id="imgHviezdicka" class="
                md:landscape:right-[70px] lg:landscape:right-[1070px] xl:landscape:right-[70px]
                md:landscape:bottom-[340px] lg:landscape:bottom-[80px] xl:bottom-[340px] xl:landscape:bottom-[340px]
                md:landscape:w-[150px] lg:landscape:w-[150px] xl:landscape:w-[170px]

                bottom-[-10px] landscape:bottom-[40px] s:bottom-[140px] landscape:right-[10px]
                right-[20px] s:right-[30px]

                z-10   w-[50px] s:w-[90px] sm:w-[130px] md:w-[150px]
                  lg:w-[170px] lg:right-[70px] 2xl:w-[200px] sm:bottom-[340px] sm:right-[30px] relative " style={styles.hviezdicka} src={hviezdicka} alt="" />
                
                <img id="img1Natalia" class=" 
                md:landscape:right-[0px] lg:landscape:right-[1000px] xl:landscape:right-[0px]
                md:landscape:bottom-[460px] lg:landscape:bottom-[190px] xl:landscape:bottom-[460px]
                md:landscape:w-[2300px] lg:landscape:w-[500px] lg:w-[2300px] xl:landscape:w-[2300px]
                z-0 top-[-20px] landscape:top-[-80px] s:top-[-200px] sm:top-[-450px] sm:landscape:top-[600px]
                md:landscape:top-[-450px] lg:landscape:top-[-200px] xl:landscape:top-[-460px] s:bottom-[460px]
                sm:w-[2300px] w-[1200px] relative " style={styles.natalia} src={natalia} alt="" />

          <img class="w-6/6 z-0 top-10 left-[-50px] s:hidden relative" style={styles.hviezdicka2} src={hviedzicka2} alt="" />
          <img class="left-[-180px] top-[-40px] w-6/6 s:hidden relative" style={styles.oblak} src={oblak} alt="" />
     
            </div>
        </div>

    </div>
  )
}

export default KtoSom