import React, { useState } from 'react'
import image1 from "../Photos/foto 1.png"
import pismena from "../Photos/foto 2.png"
import activity from "../Photos/ActivityV2.png"
import duha2 from "../Photos/foto 3.png"
import GaleriaModal from './GaleriaModal'

function Galeria({language}) {

  const [open, setOpen] = useState(false)
  const[activities, setActivities] = useState()

  const handleGallery = (n) => {
    n === 0 ? setActivities(false) : setActivities(true)
    setOpen(prevState => !prevState)
    open ? 
    document.body.style.overflowY = 'unset'
    :
    document.body.style.overflowY = 'hidden'
  }

    const styles={
        slnko:{
          width: '100%'},
        }

  return (
    <div class='bg-[#e9f9f3] h-screen w-screen pt-24' id="galeria">
        <div id="galeriaMid" class='absolute top-[2050px]'></div>


        <div class="flex-row">
            <div class=" flex-row pt-8  s:pt-14">
                <div class="flex items-center justify-center">
                  <h3 class="text-green-600 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg" style={styles.headerText}>{language ? "GALLERY" : "GALÉRIA"}</h3>
                </div>              
            </div>
        </div>

        <div class='hidden sm:hidden landscape:block landscape:s:hidden landscape:md:block lg:block'>
          <div class="flex flex-row w-160 gap-8 p-8 m-6 flex items-center justify-center">
            <div class="w-1/3 text-center ">
              <button onClick={() => handleGallery(0)}><img class="" style={styles.slnko} src={image1} alt="" /></button>
              <text class="text-yellow-300 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg" style={styles.text}>Jolly Phonics</text>
            </div>
            <div class="w-1/3 text-center">
              <button onClick={() => handleGallery(1)}><img class="" style={styles.slnko}  src={activity} alt="" /></button>
              <text class="pb-2 text-green-600 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg" style={styles.text}>{language ? "Activities" : "Aktivity"}</text>
            </div>
          </div>
        </div>

        <div class='block sm:block landscape:hidden landscape:s:hidden lg:hidden '>
          <div class="flex-column w-160 gap-8 p-8 m-6 items-center justify-center ">
            <div class="w-1/2 m-auto text-center pb-8 ">
              <button onClick={() => handleGallery(0)}><img class="" style={styles.slnko} src={image1} alt="" /></button>
              <text class="text-yellow-300 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg" style={styles.text}>Jolly Phonics</text>
            </div>
            <div class="w-1/2 m-auto text-center">
              <button onClick={() => handleGallery(1)}><img class="" style={styles.slnko}  src={activity} alt="" /></button>
              <text class="pb-2 text-green-600 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg" style={styles.text}>{language ? "Activities" : "Aktivity"}</text>
            </div>
          </div>
        </div>
    
        { open && <GaleriaModal handleGallery={handleGallery} activities={activities} language={language} activ/>}

    </div>
  )
}

export default Galeria