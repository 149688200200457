import React, { useState } from 'react'
import Header from './Components/Header';
import Main from './Components/Main';
import ONas from './Components/ONas';
import KtoSom from './Components/KtoSom';
import CoPonukam from './Components/CoPonukam';
import Galeria from './Components/Galeria';
import Kontakt from './Components/Kontakt';

function App() {

    const [language, setLanguage] = useState(false); 

    const handleLanguage = () => {
        setLanguage(prevLanguage => !prevLanguage)}

  return (
    <div>  
      <Header handleLanguage={handleLanguage} language={language}/>
      <Main language={language}/>
      <ONas language={language}/>
      <KtoSom handleLanguage={handleLanguage} language={language}/>
      <CoPonukam language={language}/>
      <Galeria language={language}/>
      <Kontakt language={language}/>  
    </div>
  )
}

export default App