import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../Images/logo_menu.svg';
import React, { useState } from 'react'

const navigation = [
  { name: 'LEARN & SHINE ACADEMY', name2: 'LEARN & SHINE ACADEMY', href: '#onas', current: false },
  { name: 'KTO SOM', name2: 'ABOUT ME', href: '#ktosom', current: false },
  { name: 'ČO PONÚKAM', name2: 'WHAT DO I OFFER', href: '#coponukam', current: false },
  { name: 'GALÉRIA', name2: 'GALLERY', href: '#galeria', current: false },
  { name: 'KONTAKT', name2: 'CONTACT', href: '#kontakt', current: false },
]

const navigation2 = [
  { name: 'LEARN & SHINE ACADEMY', name2: 'LEARN & SHINE ACADEMY', href: '#onasMid', current: false },
  { name: 'KTO SOM', name2: 'ABOUT ME', href: '#ktoSomMid', current: false },
  { name: 'ČO PONÚKAM', name2: 'MY OFFER', href: '#coPonukamMid', current: false },
  { name: 'GALÉRIA', name2: 'GALLERY', href: '#galeriaMid', current: false },
  { name: 'KONTAKT', name2: 'CONTACT', href: '#kontakt', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const styles={
  mainText:{
      fontFamily:'Satoshi-Medium',
      fontSize: '2.25em',
  },}


export default function Header({handleLanguage,language}) {

  return (
    <Disclosure as="nav" className="border-b-2 border-black sticky top-0 bg-white z-50 {{displayHeader ? visible :  invisible}}">
      {({ open }) => (
        <>
          <div className="mx-0 max-w-9xl pl-0 sm:pl-2 lg:pl-4">
            <div className="relative flex h-12 items-center justify-between bg-white-400">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                  <a href="#mainMid">
                    <img
                      className="h-8 w-auto"
                      src={logo}
                      alt="Learn Shine Academy"
                    />
                  </a>
                </div>
              </div>
              <div className="flex flex-2 items-center justify-center sm:items-stretch sm:justify-end">
                <div className="flex flex-shrink-0 items-center">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-0 ">
                    
                    {navigation.map((item) => (
                      <a
                        key={language ? item.name2 : item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-100 text-black' : 'text-black-300 hover:bg-gray-200 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {language ? item.name2 : item.name}
                      </a>
                    ))}
                    <button className='text-black-300 hover:bg-gray-200 hover:text-white
                          rounded-md px-3 py-2 text-sm font-medium' onClick={handleLanguage}>SK/EN</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={language ? item.name2 : item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {language ? item.name2 : item.name}
                </Disclosure.Button>
              ))}
               <button className={classNames(
                     'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 w-full text-left font-medium'
                  )} onClick={handleLanguage}>SK/EN</button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
