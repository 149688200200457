import React from 'react'
import kontakt from "../Images/kontakt.svg"

function Kontakt({language}) {
    const styles={
        slnko:{
          width: '75%',
        },
        mainTextBold:{
          fontFamily:'Satoshi-Bold',
          fontSize: '2em'
        },
        mainTextBoldUnder:{
          fontFamily:'Satoshi-Bold',
          fontSize: '2em',
          textDecoration: 'underline'
        },
        headerText:{
            fontFamily:'satoshi-bold',
            fontSize: '2em'
        }
    }

const text = "Natália Polanská"
const text21 = "Mail: "
const text22 = "hello.lsacademy@gmail.com"
const text31 = "Web: "
const text32 = "www.learn-shineacademy.sk"
const text41 = "Mobile: "
const text42 = "+421948798084"
const text51 = "FB: "
const text52 = "Learn & Shine Academy"

  return (
    <div class='bg-[#fff7fa] h-screen w-screen pt-6' id="kontakt">
        <div class="z-0 flex-row relative 
        h-16  s:h-48
        bottom-72 s:bottom-32
        right-20 landscape:right-14 s:right-14
        md:bottom-24 lg:bottom-24
        xl:bottom-0
        xxl:bottom-8 2xl:bottom-0">
        <img id="imgKontakt" class="
        landscape:h-[70px] landscape:top-[520px] landscape:left-[280px]
        landscape:s:h-[100px] landscape:s:top-[520px] landscape:s:left-[130px]
        landscape:md:h-[100px] landscape:md:top-[600px] landscape:md:left-[160px]
        landscape:lg:h-[400px] landscape:lg:top-96 landscape:lg:left-[800px]
        landscape:2xl:h-[280px] landscape:2xl:top-96 landscape:2xl:left-[800px]
        landscape:xxl:h-[400px] landscape:xxl:top-72 landscape:xxl:left-[800px]
        s:h-[100px] s:top-[640px] s:left-[130px]
        h-[200px] top-[640px] left-[130px]
        md:h-[100px] md:top-[600px] md:left-[160px] 
        lg:pr-[520px]
        xl:h-[400px] xl:top-72 xl:left-[800px] xl:pr-[0px]
        xxl:left-[620px] xxl:h-[320px] xxl:pr-[320px] 
        2xl:h-[400px] 2xl:top-72 2xl:left-[800px] 2xl:pr-[0px]
          relative z-0 justify-self-start inline-block" style={styles.slnko} src={kontakt} alt="" />
        </div>
        <div class="z-10 flex-row">
            <div class="z-10 flex items-center justify-center">
              <h3 class="text-blue-600 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg">{language ? "CONTACT" : "KONTAKT"}</h3>
            </div> 
        </div>

        <div class="flex flex-row w-auto gap-0 m-0 px-0 s:px-12 pt-0 s:pt-24 justify-center 
                    sm:w-auto sm:gap-0 sm:m-0 
                    md:w-auto md:gap-0 md:m-0 md:justify-center
                    lg:w-auto lg:gap-0 lg:m-0 lg:px-24 lg:justify-normal
                    xl:w-240 xl:gap-8 xl:m-6 xl:px-48 
                    2xl:w-160 2xl:gap-24 2xl:m-8 2xl:px-72">
            <div class="z-10 p-6 text-black ">
                <p class="lg:py-1 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]" >{text}</p>
                <p class="lg:py-1 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]" ><span class="text-pink-300 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]">{text21}</span><span class="font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em] underline">{text22}</span></p>
                <p class="lg:py-1 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]" ><span class="text-yellow-300 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]">{text31}</span><span class="font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]">{text32}</span></p>
                <p class="lg:py-1 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]" ><span class="text-green-600 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]">{text41}</span><span class="font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em] ">{text42}</span></p>
                <p class="lg:py-1 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]" ><span class="text-blue-600 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em] ">{text51}</span>
                <a class="lg:py-1 font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em]" href="https://www.facebook.com/profile.php?id=100085567967277"><span class="font-[satoshi-bold] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-[0.95em] underline">{text52}</span></a></p>
            </div>
        </div> 

        

    </div>
  )
}

export default Kontakt