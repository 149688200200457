import React, { useState } from 'react'
import { motion } from "framer-motion"
import zavriet from '../Images/zavriet.svg';

function ImageModal({handleImageClose,activities,language, image}) {

  const styles={
    modal:{
      position: 'fixed', 
      zIndex: 90, 
      left: 0,
      top: 0,
      width: '100%', 
      height: '100%', 
      overflow: 'hidden', 
      backgroundColor: '#e9f9f3',
    },
    zavriet:{
      width: '50px'
    }}

  return (

<motion.div
initial={{ opacity: 0 }}
animate={{ opacity: 1 }}
exit={{ opacity: 0 }}
transition={{ delay: 0.1 }}
id="imageModal" style={styles.modal } 
class="pt-[20px] md:pt-[50px] landscape:md:pt-2 landscape:lg:pt-[12px]
 lg:pt-[100px] landscape:xxl:pt-[20px] landscape:2xl:pt-[50px]">

<div class="flex mx-20">
    <div class="flex-none w-46 h-14">
        <button onClick={handleImageClose}> <p class="text-green-600 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-md md:text-lg sm:text-lg text-lg" style={styles.headerText}>{language ? "Back to gallery" : "Spať na celú galériu"}</p></button>
    </div>
    <div class="grow h-14 ..."></div>
    <div class="z-40  s:block flex-none h-14 2xl:w-w-[48px] xl:w-[48px] lg:w-[32px] md:w-[48px] sm:w-[42px] w-[36px]">
     <button onClick={handleImageClose}><img src={zavriet} alt="zavriet" style={styles.zavriet}/></button>

    </div>
</div>
<div id="galeriaModalDiv" class="px-0 pb-12 flex items-center justify-center">
        <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.05 }}
            src={image}
            alt="Img"
            class="w-72 s:w-auto h-[90%] h-[calc(90vh-92px)] max-w-fit object-cover rounded-lg z-10"/>
</div>


</motion.div>

  )
}

export default ImageModal