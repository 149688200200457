import React from 'react'
import slnko from "../Images/slnko.svg"
import dazdovka from "../Images/dazdovka.svg"
import kvetinka from "../Images/kvetinka.svg"

function ONas({language}) {
    const styles={}

    const text1 = "S našimi kurzami sa deti nenudia. Každá lekcia je skvelo pripravená a poriadne premyslená. Deti sa učia angličtinu zábavnou formou. Používame rôzne prírodné materiály, deti kreslia, maľujú a tvoria. Na naších hodinách pracujeme s rôznymi materiálmi, edukačnými pomôckami a anglickými knihami. Deti majú šancu byť kreatívne a vďaka tomu ich učenie s nami baví."
    const text2 = "Deti vedieme k ekologickému zmýšľaniu. Snažíme sa deti naučiť zamýšľať sa nad otázkami ako napríklad: Čo môžem urobiť preto aby som svet zmenil k lepšiemu? Veľa sa rozprávame o prírode a o tom aká je pre nás dôležitá. Takisto naše pocity  a preto ich stále zahŕňame v naších témach a aktivitách. Učíme deti aby si vážili samé seba a rešpektovali druhých."
    const text3 = "Spoločne pracujeme na rôznych projektoch. Deti si vytvárajú pozitívny vzťah k štúdiu. Vďaka týmto projektom, ktoré sú vytvorené ich prácov sa deti naučia vážiť si svoju tvorbu, cítia sa ocenené a nadobúdajú sebavedomie. Projekty, ktoré si spoločne vyrobíme potom prezentujeme rodičom. Spolupráca medzi učiteľom, dieťaťom a rodičom je pre nás veľmi dôležitá."

    const text1EN = "Lear & Shine Academy are English courses for children from 2-7 years old. Each lesson is well prepared and well thought out. Children will definitely not get bored with our courses. Children learn English in a fun way. We use various natural materials, children draw, paint and create. In our lessons, we work with various materials, educational aids and English books. Children have a chance to be creative and thanks to that they enjoy learning with us."
    const text2EN = "We lead children to ecological thinking. We talk to children about different important topics such as: What can I do to change the world for the better place? We talk a lot about nature and how important it is to us. Our feelings are also very important and therefore we always include them in our topics and activities. We teach children to respect themselves and respect others."
    const text3EN = "We work together on various projects. Children develop a positive attitude towards studying. Thanks to these projects, which are created from their work and photos from our lessons, children learn to appreciate their work, feel valued and gain self-confidence. We make projects together from our work in class and then present them to parents. Cooperation between teacher, child and parent is very important to us."

  return (
    <div id="onas" class='bg-[#eee7fd] h-screen w-screen pt-24'>
            <div id="onasMid" class='absolute top-[520px]'></div>

        <div  class="z-0 flex-row relative h-6 sm:h-36 bottom-32 right-14 xl:right-4 md:bottom-24 lg:bottom-24 xl:bottom-0 2xl:bottom-0 w-1/5">
            <img id="imgSlnko"  class="z-0 justify-self-start inline-block w-[50px] s:w-[60px] sm:w-[130px] md:w-[150px] lg:w-[150px] 2xl:w-[220px] top-[140px] left-[60px] relative" src={slnko} alt="" />
            <img class="hidden w-[30px] landscape:w-[60px] left-[380px] s:w-[500px] s:left-[300px] top-[130px] landscape:left-[420px] landscape:top-[100px] first-letter:block  relative  z-0" style={styles.dazdovka} src={dazdovka} alt="" />
        </div>
        <div  class="z-10 flex-row">
            <div class="z-10 flex items-center justify-center">
               <p class="z-10 pb-2 text-blue-600 font-[satoshi-black] 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-lg sm:text-lg text-lg" >{language ? "LEARN & SHINE ACADEMY" : "LEARN & SHINE ACADEMY"}</p>
            </div> 
        </div>
          <div  class="flex flex-row
                      w-full 
                      sm:w-full 
                      md:w-full md:gap-0 md:m-0 md:px-16 
                      lg:w-full lg:gap-0 lg:m-0 lg:px-16 
                      xl:w-240 xl:gap-8 xl:m-6 xl:px-48 
                      2xl:w-160 2xl:gap-24 2xl:m-8 2xl:px-72
                      ">
              <div class="z-10 p-6 text-black hidden sm:hidden md:block">
                <p class="z-10 font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-sm sm:text-sm text-xs" style={styles.text}>{language ? text1EN : text1}</p>
                <p class="z-10 pt-4 font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-sm sm:text-sm text-xs" style={styles.text}>{language ? text2EN : text2}</p>
              </div>

              <div class="p-6 text-black hidden sm:hidden md:block">
                <p class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-sm sm:text-sm text-xs" style={styles.text}>{language ? text3EN : text3}</p>
                <img id="imgDazdovka" class="w-[500px] relative left-[50px] z-0 2xl:top-[100px] xxl:top-[20px] lg:top-[10px] sm:top-[50px] justify-self-start inline-block" style={styles.dazdovka} src={dazdovka} alt="" />
                <img id="imgKvetik" class="2xl:w-[250px] xl:w-[190px] lg:w-[130px] md:w-[90px] sm:w-[60px] w-[50px] 
                2xl:left-[550px] xl:left-[460px] lg:left-[340px] md:left-[240px] sm:left-[100px] left-[100px]
                2xl:bottom-[330px] xl:bottom-[640px] lg:bottom-[560px] md:bottom-[490px] sm:bottom-[330px] bottom-[330px]
                xxl:bottom-[560px] xxl:left-[400px]
                relative  z-0 justify-self-start" style={styles.kvetinka} src={kvetinka} alt="" />
              </div>

              <div  class="z-10 p-6 text-black block sm:block md:hidden max-w-[94%]">
                <p class="z-10 font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-sm sm:text-sm text-[11px] leading-[0.75rem]" style={styles.text}>{language ? text1EN : text1}</p>
                <p class="z-10 pt-4 font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-sm sm:text-sm text-[11px] leading-[0.75rem]" style={styles.text}>{language ? text2EN : text2}</p>
                <p class="font-[satoshi-medium] 2xl:text-2xl xxl:text-base xl:text-xl lg:text-base md:text-sm sm:text-sm text-[11px] leading-[0.75rem]" style={styles.text}>{language ? text3EN : text3}</p>
                <img class=" top-[30px] s:top-[0px] w-[200px] s:block s:w-[500px] mx-auto relative left-[50px] z-0 lg:top-[100px] sm:top-[50px] justify-self-start" style={styles.dazdovka} src={dazdovka} alt="" />
                <img class="hidden s-block w-[15px] bottom-[250px] landscape:w-[30px] left-[330px] landscape:bottom-[250px] landscape:left-[410px] s:w-[40px] s:bottom-[520px] s:left-[500px]
                sm:w-[80px] sm:bottom-[680px] sm:left-[500px] z-0 justify-self-start relative" src={kvetinka} alt="" />
              </div>
          </div> 
      </div>
  )
}

export default ONas